<template lang="pug">
  tr(
    :class="{ added: inventoryGroup._addedAt }"
  )
    template(v-if="currentRowInEdit")
      td
        BFormInput(
          type="text"
          name="code"
          :value="inventoryGroup.code"
          @click.stop
          @input="handleCodeInput"
        )
      td
        BFormInput(
          type="text"
          name="code"
          :class="{ 'is-invalid': inventoryGroup.invalid }"
          :value="inventoryGroup.name"
          @click.stop
          @input="handleNameInput"
        )
    template(v-else)
      td.inventory-group-code {{ inventoryGroup.code }}
      td.inventory-group-name(:class="{ 'is-invalid': inventoryGroup.invalid }")
        | {{ inventoryGroup.name }}
    td.updated-at {{ inventoryGroup.last_updated }}
    td.actions.right
      .action-button
        AppIconButton(
          :disabled="!hasEditPermission || rowInEdit"
          icon="edit"
          @click.stop="$emit('select', index)"
        )
      .action-button(
        v-b-tooltip.hover="{ title: $t('company_system.relation_tooltips.has_shops'), placement: 'left', disabled: !inventoryGroup.unremovable }"
      )
        AppIconButton(
          :disabled="!hasEditPermission || inventoryGroup.unremovable || rowInEdit"
          useAppIcon
          icon="trash"
          @click.stop="$emit('delete', index)"
        )
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        required: true
      },
      selectedIndex: {
        type: Number,
        default: null
      },
      inventoryGroup: {
        type: Object,
        required: true
      },
      hasEditPermission: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      currentRowInEdit() {
        return this.index === this.selectedIndex
      },

      rowInEdit() {
        return this.selectedIndex !== null
      }
    },

    methods: {
      handleCodeInput(value) {
        this.$emit("change-inventory-group", { item: { ...this.inventoryGroup, code: value }, index: this.index })
      },

      handleNameInput(value) {
        this.$emit("change-inventory-group", { item: { ...this.inventoryGroup, name: value }, index: this.index })
      }
    },

    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  tr
    &.added
      background-color: $default-purple-light
    td
      &.is-invalid
        color: $default-red

      input
        &.is-invalid
          +default-invalid-input

      &.inventory-groups
        padding-left: 10px

      &.actions
        display: flex
        justify-content: end
        text-align: center

        .action-button
          padding-top: 9px
          button
            +icon-button($default-purple)
</style>
